document.addEventListener("turbolinks:load", function() {
  let scope = '[data-edit-item-modal] ';

  // Set Replacement Due date when Useful Life changes
  $(document.body).on('keyup', scope + '[data-useful-life-years]', function(e) {
    updateReplacementDueDate(scope);
  });

  // Set Replacement Due date when Purchase Date changes
  $(document.body).on('change', scope + '[data-purchase-date]', function(e) {
    updateReplacementDueDate(scope);
    warnOnFutureDate(scope);
  });

  // Intercept form submission and warn if purchase date is in the future
  let clickedSubmitButton = null;
  $(document.body).on('click', scope + '[data-item-form] input[type=submit]', function() {
    clickedSubmitButton = this.value;
  });

  $(document.body).on('ajax:beforeSend', scope + '[data-item-form]', function(e) {
    if (clickedSubmitButton && (clickedSubmitButton === 'Save')) {
      let purchaseDateEl = $(scope + '[data-purchase-date]');
      let purchaseDate = window.dateFnsParse(purchaseDateEl.val(), 'yyyy-MM-dd', new Date());
      let today = new Date();

      if (purchaseDate > today) {
        if (!window.confirm('This purchase date is in the future. Are you sure you want to continue?')) {
          e.preventDefault();
        }
      }
    }
  });

  // Also provide a warning message next to purchase date field if purchase date is in the future
  function warnOnFutureDate(scope) {
    let purchaseDateEl = $(scope + '[data-purchase-date]');
    let purchaseDate = window.dateFnsParse(purchaseDateEl.val(), 'yyyy-MM-dd', new Date());
    let today = new Date();

    let futureDateWarning = $(scope + '[data-future-date-warning]');

    if (purchaseDate > today) {
      futureDateWarning.show();
    } else {
      futureDateWarning.hide();
    }
  }

  // Calculate Replacement Date from Purchase Date + Useful Life
  function updateReplacementDueDate(scope) {
    let usefulLifeYearsEl = $(scope + '[data-useful-life-years]');
    let purchaseDateEl = $(scope + '[data-purchase-date]');
    let replacementDueDateEl = $(scope + '[data-replacement-due-date]');

    let usefulLifeYears = usefulLifeYearsEl.val();
    let purchaseDate = window.dateFnsParse(purchaseDateEl.val(), 'yyyy-MM-dd', new Date());

    if(usefulLifeYears && purchaseDate) {
      let date = window.dateFnsAddYears(purchaseDate, usefulLifeYears);
      let formattedDate = window.dateFnsFormat(date, 'yyyy-MM-dd');
      replacementDueDateEl.val(formattedDate);
    }
  }

  // Set insurance value and replacement cost to purchase cost
  $(document.body).on('keyup', scope + '[data-purchase-cost]', function(e) {
    let copy_check = $(scope + '[data-copy-purchase-cost]')[0];
    if(copy_check && copy_check.checked) {
      let cost = document.getCleave(e.target);

      let insurance_input = $(scope + '[data-insurance-value]');
      insurance_input.val(cost.value);

      let replacement_cost_input = $(scope + '[data-replacement-cost]');
      replacement_cost_input.val(cost.value);

      document.applyCleave();
    }
  });

  // Handle changing of Category and updating related fields -
  // asking first if they have been manually changed

  $(document.body).on('change', scope + '[data-select-item-category]', function(e) {

      let opt = $(e.target.selectedOptions[0]);
      let usefulLifeYears = opt.data('opt-useful-life-years');

      if(usefulLifeYears) {
        let input = $(scope + '[data-useful-life-years]');

        if (input.data('protection') === 'none') {
          setUsefulLifeYears(input, usefulLifeYears);
        } else {
          let msg = 'Selecting OK will change the Useful Life for this Item to ' +
            usefulLifeYears + ' years';

          if (confirm(msg)) {
            setUsefulLifeYears(input, usefulLifeYears);
          }
        }

        updateReplacementDueDate(scope);
      }
    }
  );

  // Set useful life to category useful life

  function setUsefulLifeYears(input, usefulLifeYears) {
    input.val(usefulLifeYears);
  }

  $(document.body).on('change', scope + '[data-useful-life-years]', function(e) {
      // manual changes are protected
    if(!e.isTrigger) $(e.target).data('protection', 'protected');
    }
  );

  // Notify server if minor item modal is closed - so it can delete unsaved item
  $(document.body).on("close_notify", "[data-item-close-notify]", function (e) {
    let assetId = $(e.target).data('item-close-notify');
    let url = Routes.close_notify_admin_minor_item_path(assetId);
    $.ajax({
      method: 'PUT',
      url: url
    });
  });

  // Open Item Edit modal if requested (input is rendered on the Index page

  let editItemId = $('input#edit_item').val();
  if(editItemId) {
    let url = Routes.edit_admin_minor_item_path(editItemId);
    $.ajax({
      method: 'GET',
      url: url
    });
  }

});

// Check if the form is marked 'dirty' and warn
// - so user doesn't lose changes when clicking the history link
document.addEventListener("turbolinks:before-visit", function(e) {
  if ($('[data-edit-asset-modal] form').hasClass('dirty')) {
    if(!confirm('Any unsaved changes will be lost, are you sure?')) {
      e.preventDefault();
    }
  }
});

