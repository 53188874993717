/*
File generated by js-routes 1.4.14
Based on Rails 6.1.7.4 routes of AssetManager::Application
 */

(function() {
  var DeprecatedGlobbingBehavior, NodeTypes, ParameterMissing, ReservedOptions, SpecialOptionsKey, UriEncoderSegmentRegex, Utils, error, result,
    hasProp = {}.hasOwnProperty,
    slice = [].slice;

  ParameterMissing = function(message, fileName, lineNumber) {
    var instance;
    instance = new Error(message, fileName, lineNumber);
    if (Object.setPrototypeOf) {
      Object.setPrototypeOf(instance, Object.getPrototypeOf(this));
    } else {
      instance.__proto__ = this.__proto__;
    }
    if (Error.captureStackTrace) {
      Error.captureStackTrace(instance, ParameterMissing);
    }
    return instance;
  };

  ParameterMissing.prototype = Object.create(Error.prototype, {
    constructor: {
      value: Error,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });

  if (Object.setPrototypeOf) {
    Object.setPrototypeOf(ParameterMissing, Error);
  } else {
    ParameterMissing.__proto__ = Error;
  }

  NodeTypes = {"GROUP":1,"CAT":2,"SYMBOL":3,"OR":4,"STAR":5,"LITERAL":6,"SLASH":7,"DOT":8};

  DeprecatedGlobbingBehavior = false;

  SpecialOptionsKey = "_options";

  UriEncoderSegmentRegex = /[^a-zA-Z0-9\-\._~!\$&'\(\)\*\+,;=:@]/g;

  ReservedOptions = ['anchor', 'trailing_slash', 'subdomain', 'host', 'port', 'protocol'];

  Utils = {
    configuration: {
      prefix: "",
      default_url_options: {},
      special_options_key: "_options",
      serializer: null
    },
    default_serializer: function(object, prefix) {
      var element, i, j, key, len, prop, s;
      if (prefix == null) {
        prefix = null;
      }
      if (object == null) {
        return "";
      }
      if (!prefix && !(this.get_object_type(object) === "object")) {
        throw new Error("Url parameters should be a javascript hash");
      }
      s = [];
      switch (this.get_object_type(object)) {
        case "array":
          for (i = j = 0, len = object.length; j < len; i = ++j) {
            element = object[i];
            s.push(this.default_serializer(element, prefix + "[]"));
          }
          break;
        case "object":
          for (key in object) {
            if (!hasProp.call(object, key)) continue;
            prop = object[key];
            if ((prop == null) && (prefix != null)) {
              prop = "";
            }
            if (prop != null) {
              if (prefix != null) {
                key = prefix + "[" + key + "]";
              }
              s.push(this.default_serializer(prop, key));
            }
          }
          break;
        default:
          if (object != null) {
            s.push((encodeURIComponent(prefix.toString())) + "=" + (encodeURIComponent(object.toString())));
          }
      }
      if (!s.length) {
        return "";
      }
      return s.join("&");
    },
    serialize: function(object) {
      var custom_serializer;
      custom_serializer = this.configuration.serializer;
      if ((custom_serializer != null) && this.get_object_type(custom_serializer) === "function") {
        return custom_serializer(object);
      } else {
        return this.default_serializer(object);
      }
    },
    clean_path: function(path) {
      var last_index;
      path = path.split("://");
      last_index = path.length - 1;
      path[last_index] = path[last_index].replace(/\/+/g, "/");
      return path.join("://");
    },
    extract_options: function(number_of_params, args) {
      var last_el, options;
      last_el = args[args.length - 1];
      if ((args.length > number_of_params && last_el === void 0) || ((last_el != null) && "object" === this.get_object_type(last_el) && !this.looks_like_serialized_model(last_el))) {
        options = args.pop() || {};
        delete options[this.configuration.special_options_key];
        return options;
      } else {
        return {};
      }
    },
    looks_like_serialized_model: function(object) {
      return !object[this.configuration.special_options_key] && ("id" in object || "to_param" in object);
    },
    path_identifier: function(object) {
      var property;
      if (object === 0) {
        return "0";
      }
      if (!object) {
        return "";
      }
      property = object;
      if (this.get_object_type(object) === "object") {
        if ("to_param" in object) {
          if (object.to_param == null) {
            throw new ParameterMissing("Route parameter missing: to_param");
          }
          property = object.to_param;
        } else if ("id" in object) {
          if (object.id == null) {
            throw new ParameterMissing("Route parameter missing: id");
          }
          property = object.id;
        } else {
          property = object;
        }
        if (this.get_object_type(property) === "function") {
          property = property.call(object);
        }
      }
      return property.toString();
    },
    clone: function(obj) {
      var attr, copy, key;
      if ((obj == null) || "object" !== this.get_object_type(obj)) {
        return obj;
      }
      copy = obj.constructor();
      for (key in obj) {
        if (!hasProp.call(obj, key)) continue;
        attr = obj[key];
        copy[key] = attr;
      }
      return copy;
    },
    merge: function() {
      var tap, xs;
      xs = 1 <= arguments.length ? slice.call(arguments, 0) : [];
      tap = function(o, fn) {
        fn(o);
        return o;
      };
      if ((xs != null ? xs.length : void 0) > 0) {
        return tap({}, function(m) {
          var j, k, len, results, v, x;
          results = [];
          for (j = 0, len = xs.length; j < len; j++) {
            x = xs[j];
            results.push((function() {
              var results1;
              results1 = [];
              for (k in x) {
                v = x[k];
                results1.push(m[k] = v);
              }
              return results1;
            })());
          }
          return results;
        });
      }
    },
    normalize_options: function(parts, required_parts, default_options, actual_parameters) {
      var i, j, key, len, options, part, parts_options, result, route_parts, url_parameters, use_all_parts, value;
      options = this.extract_options(parts.length, actual_parameters);
      if (actual_parameters.length > parts.length) {
        throw new Error("Too many parameters provided for path");
      }
      use_all_parts = actual_parameters.length > required_parts.length;
      parts_options = {};
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        use_all_parts = true;
        if (this.indexOf(parts, key) >= 0) {
          parts_options[key] = value;
        }
      }
      options = this.merge(this.configuration.default_url_options, default_options, options);
      result = {};
      url_parameters = {};
      result['url_parameters'] = url_parameters;
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        value = options[key];
        if (this.indexOf(ReservedOptions, key) >= 0) {
          result[key] = value;
        } else {
          url_parameters[key] = value;
        }
      }
      route_parts = use_all_parts ? parts : required_parts;
      i = 0;
      for (j = 0, len = route_parts.length; j < len; j++) {
        part = route_parts[j];
        if (i < actual_parameters.length) {
          if (!parts_options.hasOwnProperty(part)) {
            url_parameters[part] = actual_parameters[i];
            ++i;
          }
        }
      }
      return result;
    },
    build_route: function(parts, required_parts, default_options, route, full_url, args) {
      var options, parameters, result, url, url_params;
      args = Array.prototype.slice.call(args);
      options = this.normalize_options(parts, required_parts, default_options, args);
      parameters = options['url_parameters'];
      result = "" + (this.get_prefix()) + (this.visit(route, parameters));
      url = Utils.clean_path(result);
      if (options['trailing_slash'] === true) {
        url = url.replace(/(.*?)[\/]?$/, "$1/");
      }
      if ((url_params = this.serialize(parameters)).length) {
        url += "?" + url_params;
      }
      url += options.anchor ? "#" + options.anchor : "";
      if (full_url) {
        url = this.route_url(options) + url;
      }
      return url;
    },
    visit: function(route, parameters, optional) {
      var left, left_part, right, right_part, type, value;
      if (optional == null) {
        optional = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return this.visit(left, parameters, true);
        case NodeTypes.STAR:
          return this.visit_globbing(left, parameters, true);
        case NodeTypes.LITERAL:
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
          return left;
        case NodeTypes.CAT:
          left_part = this.visit(left, parameters, optional);
          right_part = this.visit(right, parameters, optional);
          if (optional && ((this.is_optional_node(left[0]) && !left_part) || ((this.is_optional_node(right[0])) && !right_part))) {
            return "";
          }
          return "" + left_part + right_part;
        case NodeTypes.SYMBOL:
          value = parameters[left];
          delete parameters[left];
          if (value != null) {
            return this.encode_segment(this.path_identifier(value));
          }
          if (optional) {
            return "";
          } else {
            throw new ParameterMissing("Route parameter missing: " + left);
          }
          break;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    encode_segment: function(segment) {
      return segment.replace(UriEncoderSegmentRegex, function(str) {
        return encodeURIComponent(str);
      });
    },
    is_optional_node: function(node) {
      return this.indexOf([NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT], node) >= 0;
    },
    build_path_spec: function(route, wildcard) {
      var left, right, type;
      if (wildcard == null) {
        wildcard = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return "(" + (this.build_path_spec(left)) + ")";
        case NodeTypes.CAT:
          return "" + (this.build_path_spec(left)) + (this.build_path_spec(right));
        case NodeTypes.STAR:
          return this.build_path_spec(left, true);
        case NodeTypes.SYMBOL:
          if (wildcard === true) {
            return "" + (left[0] === '*' ? '' : '*') + left;
          } else {
            return ":" + left;
          }
          break;
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
        case NodeTypes.LITERAL:
          return left;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    visit_globbing: function(route, parameters, optional) {
      var left, right, type, value;
      type = route[0], left = route[1], right = route[2];
      value = parameters[left];
      delete parameters[left];
      if (value == null) {
        return this.visit(route, parameters, optional);
      }
      value = (function() {
        switch (this.get_object_type(value)) {
          case "array":
            return value.join("/");
          default:
            return value;
        }
      }).call(this);
      if (DeprecatedGlobbingBehavior) {
        return this.path_identifier(value);
      } else {
        return encodeURI(this.path_identifier(value));
      }
    },
    get_prefix: function() {
      var prefix;
      prefix = this.configuration.prefix;
      if (prefix !== "") {
        prefix = (prefix.match("/$") ? prefix : prefix + "/");
      }
      return prefix;
    },
    route: function(parts_table, default_options, route_spec, full_url) {
      var j, len, part, parts, path_fn, ref, required, required_parts;
      required_parts = [];
      parts = [];
      for (j = 0, len = parts_table.length; j < len; j++) {
        ref = parts_table[j], part = ref[0], required = ref[1];
        parts.push(part);
        if (required) {
          required_parts.push(part);
        }
      }
      path_fn = function() {
        return Utils.build_route(parts, required_parts, default_options, route_spec, full_url, arguments);
      };
      path_fn.required_params = required_parts;
      path_fn.toString = function() {
        return Utils.build_path_spec(route_spec);
      };
      return path_fn;
    },
    route_url: function(route_defaults) {
      var hostname, port, protocol, subdomain;
      if (typeof route_defaults === 'string') {
        return route_defaults;
      }
      hostname = route_defaults.host || Utils.current_host();
      if (!hostname) {
        return '';
      }
      subdomain = route_defaults.subdomain ? route_defaults.subdomain + '.' : '';
      protocol = route_defaults.protocol || Utils.current_protocol();
      port = route_defaults.port || (!route_defaults.host ? Utils.current_port() : void 0);
      port = port ? ":" + port : '';
      return protocol + "://" + subdomain + hostname + port;
    },
    has_location: function() {
      return (typeof window !== "undefined" && window !== null ? window.location : void 0) != null;
    },
    current_host: function() {
      if (this.has_location()) {
        return window.location.hostname;
      } else {
        return null;
      }
    },
    current_protocol: function() {
      if (this.has_location() && window.location.protocol !== '') {
        return window.location.protocol.replace(/:$/, '');
      } else {
        return 'http';
      }
    },
    current_port: function() {
      if (this.has_location() && window.location.port !== '') {
        return window.location.port;
      } else {
        return '';
      }
    },
    _classToTypeCache: null,
    _classToType: function() {
      var j, len, name, ref;
      if (this._classToTypeCache != null) {
        return this._classToTypeCache;
      }
      this._classToTypeCache = {};
      ref = "Boolean Number String Function Array Date RegExp Object Error".split(" ");
      for (j = 0, len = ref.length; j < len; j++) {
        name = ref[j];
        this._classToTypeCache["[object " + name + "]"] = name.toLowerCase();
      }
      return this._classToTypeCache;
    },
    get_object_type: function(obj) {
      if (this.jQuery && (this.jQuery.type != null)) {
        return this.jQuery.type(obj);
      }
      if (obj == null) {
        return "" + obj;
      }
      if (typeof obj === "object" || typeof obj === "function") {
        return this._classToType()[Object.prototype.toString.call(obj)] || "object";
      } else {
        return typeof obj;
      }
    },
    indexOf: function(array, element) {
      if (Array.prototype.indexOf) {
        return array.indexOf(element);
      } else {
        return this.indexOfImplementation(array, element);
      }
    },
    indexOfImplementation: function(array, element) {
      var el, i, j, len, result;
      result = -1;
      for (i = j = 0, len = array.length; j < len; i = ++j) {
        el = array[i];
        if (el === element) {
          result = i;
        }
      }
      return result;
    },
    namespace: function(root, namespace, routes) {
      var index, j, len, part, parts;
      parts = namespace ? namespace.split(".") : [];
      if (parts.length === 0) {
        return routes;
      }
      for (index = j = 0, len = parts.length; j < len; index = ++j) {
        part = parts[index];
        if (index < parts.length - 1) {
          root = (root[part] || (root[part] = {}));
        } else {
          return root[part] = routes;
        }
      }
    },
    configure: function(new_config) {
      return this.configuration = this.merge(this.configuration, new_config);
    },
    config: function() {
      return this.clone(this.configuration);
    },
    make: function() {
      var routes;
      routes = {
// admin_asset_change => /admin/asset_changes/:id(.:format)
  // function(id, options)
  admin_asset_change_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"asset_changes",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_asset_history => /admin/asset_history/:id(.:format)
  // function(id, options)
  admin_asset_history_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"asset_history",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_asset_imports => /admin/managed_assets/asset_imports(.:format)
  // function(options)
  admin_asset_imports_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"managed_assets",false],[2,[7,"/",false],[2,[6,"asset_imports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_asset_selection => /admin/asset_selections/:id(.:format)
  // function(id, options)
  admin_asset_selection_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"asset_selections",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_asset_selections => /admin/asset_selections(.:format)
  // function(options)
  admin_asset_selections_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"asset_selections",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_bulk_disposal => /admin/bulk_disposals/:id(.:format)
  // function(id, options)
  admin_bulk_disposal_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"bulk_disposals",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_bulk_disposals => /admin/bulk_disposals(.:format)
  // function(options)
  admin_bulk_disposals_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"bulk_disposals",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_categories => /admin/categories(.:format)
  // function(options)
  admin_categories_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"categories",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_category => /admin/categories/:id(.:format)
  // function(id, options)
  admin_category_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"categories",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_department => /admin/departments/:id(.:format)
  // function(id, options)
  admin_department_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"departments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_departments => /admin/departments(.:format)
  // function(options)
  admin_departments_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"departments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_depreciation => /admin/depreciation/:id(.:format)
  // function(id, options)
  admin_depreciation_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"depreciation",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_depreciation_index => /admin/depreciation(.:format)
  // function(options)
  admin_depreciation_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"depreciation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_disposal => /admin/disposals/:id(.:format)
  // function(id, options)
  admin_disposal_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"disposals",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_draft_asset_edit_convert => /admin/draft_assets/:draft_asset_id/edit_convert(.:format)
  // function(draft_asset_id, options)
  admin_draft_asset_edit_convert_path: Utils.route([["draft_asset_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"draft_assets",false],[2,[7,"/",false],[2,[3,"draft_asset_id",false],[2,[7,"/",false],[2,[6,"edit_convert",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_draft_asset_hide => /admin/draft_assets/:draft_asset_id/hide(.:format)
  // function(draft_asset_id, options)
  admin_draft_asset_hide_path: Utils.route([["draft_asset_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"draft_assets",false],[2,[7,"/",false],[2,[3,"draft_asset_id",false],[2,[7,"/",false],[2,[6,"hide",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_draft_asset_reset => /admin/draft_assets/:draft_asset_id/reset(.:format)
  // function(draft_asset_id, options)
  admin_draft_asset_reset_path: Utils.route([["draft_asset_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"draft_assets",false],[2,[7,"/",false],[2,[3,"draft_asset_id",false],[2,[7,"/",false],[2,[6,"reset",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_draft_asset_resync => /admin/draft_assets/:draft_asset_id/resync(.:format)
  // function(draft_asset_id, options)
  admin_draft_asset_resync_path: Utils.route([["draft_asset_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"draft_assets",false],[2,[7,"/",false],[2,[3,"draft_asset_id",false],[2,[7,"/",false],[2,[6,"resync",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_draft_asset_unhide => /admin/draft_assets/:draft_asset_id/unhide(.:format)
  // function(draft_asset_id, options)
  admin_draft_asset_unhide_path: Utils.route([["draft_asset_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"draft_assets",false],[2,[7,"/",false],[2,[3,"draft_asset_id",false],[2,[7,"/",false],[2,[6,"unhide",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_draft_assets => /admin/draft_assets(.:format)
  // function(options)
  admin_draft_assets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"draft_assets",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_faculties => /admin/faculties(.:format)
  // function(options)
  admin_faculties_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"faculties",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_faculty => /admin/faculties/:id(.:format)
  // function(id, options)
  admin_faculty_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"faculties",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_location => /admin/locations/:id(.:format)
  // function(id, options)
  admin_location_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_locations => /admin/locations(.:format)
  // function(options)
  admin_locations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"locations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_managed_asset => /admin/managed_assets/:id(.:format)
  // function(id, options)
  admin_managed_asset_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"managed_assets",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_managed_assets => /admin/managed_assets(.:format)
  // function(options)
  admin_managed_assets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"managed_assets",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_minor_item => /admin/minor_items/:id(.:format)
  // function(id, options)
  admin_minor_item_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"minor_items",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_minor_items => /admin/minor_items(.:format)
  // function(options)
  admin_minor_items_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"minor_items",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_organisation => /admin/organisations/:id(.:format)
  // function(id, options)
  admin_organisation_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"organisations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_organisations => /admin/organisations(.:format)
  // function(options)
  admin_organisations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"organisations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_purchase => /admin/purchases/:id(.:format)
  // function(id, options)
  admin_purchase_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"purchases",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_registration => /admin/registrations/:id(.:format)
  // function(id, options)
  admin_registration_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"registrations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_registrations => /admin/registrations(.:format)
  // function(options)
  admin_registrations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"registrations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_site => /admin/sites/:id(.:format)
  // function(id, options)
  admin_site_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"sites",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_sites => /admin/sites(.:format)
  // function(options)
  admin_sites_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"sites",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_supplier => /admin/suppliers/:id(.:format)
  // function(id, options)
  admin_supplier_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"suppliers",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_suppliers => /admin/suppliers(.:format)
  // function(options)
  admin_suppliers_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"suppliers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_user => /admin/users/:id(.:format)
  // function(id, options)
  admin_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_users => /admin/users(.:format)
  // function(options)
  admin_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_xero_logs => /admin/xero_logs(.:format)
  // function(options)
  admin_xero_logs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"xero_logs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// cancel_user_registration => /users/cancel(.:format)
  // function(options)
  cancel_user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// change_year_end_admin_organisation => /admin/organisations/:id/change_year_end(.:format)
  // function(id, options)
  change_year_end_admin_organisation_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"organisations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"change_year_end",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// change_year_end_update_admin_organisation => /admin/organisations/:id/change_year_end_update(.:format)
  // function(id, options)
  change_year_end_update_admin_organisation_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"organisations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"change_year_end_update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// close_notify_admin_managed_asset => /admin/managed_assets/:id/close_notify(.:format)
  // function(id, options)
  close_notify_admin_managed_asset_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"managed_assets",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"close_notify",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// close_notify_admin_minor_item => /admin/minor_items/:id/close_notify(.:format)
  // function(id, options)
  close_notify_admin_minor_item_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"minor_items",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"close_notify",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// confirm => /confirm(.:format)
  // function(options)
  confirm_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"confirm",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// destroy_confirmation_admin_managed_asset => /admin/managed_assets/:id/destroy_confirmation(.:format)
  // function(id, options)
  destroy_confirmation_admin_managed_asset_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"managed_assets",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"destroy_confirmation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// destroy_confirmation_admin_minor_item => /admin/minor_items/:id/destroy_confirmation(.:format)
  // function(id, options)
  destroy_confirmation_admin_minor_item_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"minor_items",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"destroy_confirmation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// destroy_file_admin_managed_asset => /admin/managed_assets/:id/destroy_file(.:format)
  // function(id, options)
  destroy_file_admin_managed_asset_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"managed_assets",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"destroy_file",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// destroy_file_admin_minor_item => /admin/minor_items/:id/destroy_file(.:format)
  // function(id, options)
  destroy_file_admin_minor_item_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"minor_items",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"destroy_file",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// destroy_user_session => /users/sign_out(.:format)
  // function(options)
  destroy_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_out",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// edit_admin_bulk_disposal => /admin/bulk_disposals/:id/edit(.:format)
  // function(id, options)
  edit_admin_bulk_disposal_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"bulk_disposals",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_category => /admin/categories/:id/edit(.:format)
  // function(id, options)
  edit_admin_category_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"categories",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_department => /admin/departments/:id/edit(.:format)
  // function(id, options)
  edit_admin_department_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"departments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_depreciation => /admin/depreciation/:id/edit(.:format)
  // function(id, options)
  edit_admin_depreciation_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"depreciation",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_faculty => /admin/faculties/:id/edit(.:format)
  // function(id, options)
  edit_admin_faculty_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"faculties",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_location => /admin/locations/:id/edit(.:format)
  // function(id, options)
  edit_admin_location_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_managed_asset => /admin/managed_assets/:id/edit(.:format)
  // function(id, options)
  edit_admin_managed_asset_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"managed_assets",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_minor_item => /admin/minor_items/:id/edit(.:format)
  // function(id, options)
  edit_admin_minor_item_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"minor_items",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_organisation => /admin/organisations/:id/edit(.:format)
  // function(id, options)
  edit_admin_organisation_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"organisations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_site => /admin/sites/:id/edit(.:format)
  // function(id, options)
  edit_admin_site_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"sites",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_supplier => /admin/suppliers/:id/edit(.:format)
  // function(id, options)
  edit_admin_supplier_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"suppliers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_user => /admin/users/:id/edit(.:format)
  // function(id, options)
  edit_admin_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_codes_admin_draft_assets => /admin/draft_assets/edit_codes(.:format)
  // function(options)
  edit_codes_admin_draft_assets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"draft_assets",false],[2,[7,"/",false],[2,[6,"edit_codes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_disposal_code_admin_categories => /admin/categories/edit_disposal_code(.:format)
  // function(options)
  edit_disposal_code_admin_categories_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"categories",false],[2,[7,"/",false],[2,[6,"edit_disposal_code",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_rails_conductor_inbound_email => /rails/conductor/action_mailbox/inbound_emails/:id/edit(.:format)
  // function(id, options)
  edit_rails_conductor_inbound_email_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_user_password => /users/password/edit(.:format)
  // function(options)
  edit_user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_user_registration => /users/edit(.:format)
  // function(options)
  edit_user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// export_admin_asset_exports => /admin/managed_assets/asset_exports/export(.:format)
  // function(options)
  export_admin_asset_exports_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"managed_assets",false],[2,[7,"/",false],[2,[6,"asset_exports",false],[2,[7,"/",false],[2,[6,"export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// export_update_admin_asset_exports => /admin/managed_assets/asset_exports/export_update(.:format)
  // function(options)
  export_update_admin_asset_exports_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"managed_assets",false],[2,[7,"/",false],[2,[6,"asset_exports",false],[2,[7,"/",false],[2,[6,"export_update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// get_status_admin_depreciation_index => /admin/depreciation/get_status(.:format)
  // function(options)
  get_status_admin_depreciation_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"depreciation",false],[2,[7,"/",false],[2,[6,"get_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// health_check => /health_check
  // function(options)
  health_check_path: Utils.route([], {}, [2,[7,"/",false],[6,"health_check",false]]),
// health_check.root => /health_check/
  // function(options)
  health_check_root_path: Utils.route([], {}, [2,[2,[7,"/",false],[6,"health_check",false]],[7,"/",false]]),
// health_check.run => /health_check/run(.:format)
  // function(options)
  health_check_run_path: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"health_check",false]],[7,"/",false]],[2,[6,"run",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// help_index => /help/index(.:format)
  // function(options)
  help_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"help",false],[2,[7,"/",false],[2,[6,"index",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// home_index => /home/index(.:format)
  // function(options)
  home_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"home",false],[2,[7,"/",false],[2,[6,"index",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// manual_depr_admin_managed_asset => /admin/managed_assets/:id/manual_depr(.:format)
  // function(id, options)
  manual_depr_admin_managed_asset_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"managed_assets",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"manual_depr",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// manual_depr_update_admin_managed_asset => /admin/managed_assets/:id/manual_depr_update(.:format)
  // function(id, options)
  manual_depr_update_admin_managed_asset_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"managed_assets",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"manual_depr_update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// manual_sync_admin_draft_assets => /admin/draft_assets/manual_sync(.:format)
  // function(options)
  manual_sync_admin_draft_assets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"draft_assets",false],[2,[7,"/",false],[2,[6,"manual_sync",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_asset_import => /admin/managed_assets/asset_imports/new(.:format)
  // function(options)
  new_admin_asset_import_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"managed_assets",false],[2,[7,"/",false],[2,[6,"asset_imports",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_admin_asset_selection => /admin/asset_selections/new(.:format)
  // function(options)
  new_admin_asset_selection_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"asset_selections",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_bulk_disposal => /admin/bulk_disposals/new(.:format)
  // function(options)
  new_admin_bulk_disposal_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"bulk_disposals",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_category => /admin/categories/new(.:format)
  // function(options)
  new_admin_category_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"categories",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_department => /admin/departments/new(.:format)
  // function(options)
  new_admin_department_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"departments",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_depreciation => /admin/depreciation/new(.:format)
  // function(options)
  new_admin_depreciation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"depreciation",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_faculty => /admin/faculties/new(.:format)
  // function(options)
  new_admin_faculty_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"faculties",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_location => /admin/locations/new(.:format)
  // function(options)
  new_admin_location_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_managed_asset => /admin/managed_assets/new(.:format)
  // function(options)
  new_admin_managed_asset_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"managed_assets",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_minor_item => /admin/minor_items/new(.:format)
  // function(options)
  new_admin_minor_item_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"minor_items",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_organisation => /admin/organisations/new(.:format)
  // function(options)
  new_admin_organisation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"organisations",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_registration => /admin/registrations/new(.:format)
  // function(options)
  new_admin_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"registrations",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_site => /admin/sites/new(.:format)
  // function(options)
  new_admin_site_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"sites",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_supplier => /admin/suppliers/new(.:format)
  // function(options)
  new_admin_supplier_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"suppliers",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_user => /admin/users/new(.:format)
  // function(options)
  new_admin_user_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_rails_conductor_inbound_email => /rails/conductor/action_mailbox/inbound_emails/new(.:format)
  // function(options)
  new_rails_conductor_inbound_email_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_rails_conductor_inbound_email_source => /rails/conductor/action_mailbox/inbound_emails/sources/new(.:format)
  // function(options)
  new_rails_conductor_inbound_email_source_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"sources",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// new_user_confirmation => /users/confirmation/new(.:format)
  // function(options)
  new_user_confirmation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"confirmation",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_user_password => /users/password/new(.:format)
  // function(options)
  new_user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_user_registration => /users/sign_up(.:format)
  // function(options)
  new_user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_up",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_user_session => /users/sign_in(.:format)
  // function(options)
  new_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_user_unlock => /users/unlock/new(.:format)
  // function(options)
  new_user_unlock_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"unlock",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// override_depr_rate_admin_managed_asset => /admin/managed_assets/:id/override_depr_rate(.:format)
  // function(id, options)
  override_depr_rate_admin_managed_asset_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"managed_assets",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"override_depr_rate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// override_depr_rate_update_admin_managed_asset => /admin/managed_assets/:id/override_depr_rate_update(.:format)
  // function(id, options)
  override_depr_rate_update_admin_managed_asset_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"managed_assets",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"override_depr_rate_update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// post_to_xero_admin_depreciation => /admin/depreciation/:id/post_to_xero(.:format)
  // function(id, options)
  post_to_xero_admin_depreciation_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"depreciation",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"post_to_xero",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// post_to_xero_admin_disposal => /admin/disposals/:id/post_to_xero(.:format)
  // function(id, options)
  post_to_xero_admin_disposal_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"disposals",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"post_to_xero",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// preview_admin_bulk_disposal => /admin/bulk_disposals/:id/preview(.:format)
  // function(id, options)
  preview_admin_bulk_disposal_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"bulk_disposals",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"preview",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// purchase_admin_managed_asset => /admin/managed_assets/:id/purchase(.:format)
  // function(id, options)
  purchase_admin_managed_asset_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"managed_assets",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"purchase",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// purchase_update_admin_managed_asset => /admin/managed_assets/:id/purchase_update(.:format)
  // function(id, options)
  purchase_update_admin_managed_asset_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"managed_assets",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"purchase_update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_blob_representation => /rails/active_storage/representations/redirect/:signed_blob_id/:variation_key/*filename(.:format)
  // function(signed_blob_id, variation_key, filename, options)
  rails_blob_representation_path: Utils.route([["signed_blob_id",true],["variation_key",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"representations",false],[2,[7,"/",false],[2,[6,"redirect",false],[2,[7,"/",false],[2,[3,"signed_blob_id",false],[2,[7,"/",false],[2,[3,"variation_key",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// rails_blob_representation_proxy => /rails/active_storage/representations/proxy/:signed_blob_id/:variation_key/*filename(.:format)
  // function(signed_blob_id, variation_key, filename, options)
  rails_blob_representation_proxy_path: Utils.route([["signed_blob_id",true],["variation_key",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"representations",false],[2,[7,"/",false],[2,[6,"proxy",false],[2,[7,"/",false],[2,[3,"signed_blob_id",false],[2,[7,"/",false],[2,[3,"variation_key",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// rails_conductor_inbound_email => /rails/conductor/action_mailbox/inbound_emails/:id(.:format)
  // function(id, options)
  rails_conductor_inbound_email_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_conductor_inbound_email_reroute => /rails/conductor/action_mailbox/:inbound_email_id/reroute(.:format)
  // function(inbound_email_id, options)
  rails_conductor_inbound_email_reroute_path: Utils.route([["inbound_email_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[3,"inbound_email_id",false],[2,[7,"/",false],[2,[6,"reroute",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_conductor_inbound_email_sources => /rails/conductor/action_mailbox/inbound_emails/sources(.:format)
  // function(options)
  rails_conductor_inbound_email_sources_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"sources",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_conductor_inbound_emails => /rails/conductor/action_mailbox/inbound_emails(.:format)
  // function(options)
  rails_conductor_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_direct_uploads => /rails/active_storage/direct_uploads(.:format)
  // function(options)
  rails_direct_uploads_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"direct_uploads",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// rails_disk_service => /rails/active_storage/disk/:encoded_key/*filename(.:format)
  // function(encoded_key, filename, options)
  rails_disk_service_path: Utils.route([["encoded_key",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"disk",false],[2,[7,"/",false],[2,[3,"encoded_key",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_info => /rails/info(.:format)
  // function(options)
  rails_info_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"info",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// rails_info_properties => /rails/info/properties(.:format)
  // function(options)
  rails_info_properties_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"info",false],[2,[7,"/",false],[2,[6,"properties",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// rails_info_routes => /rails/info/routes(.:format)
  // function(options)
  rails_info_routes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"info",false],[2,[7,"/",false],[2,[6,"routes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// rails_mailers => /rails/mailers(.:format)
  // function(options)
  rails_mailers_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"mailers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// rails_mailgun_inbound_emails => /rails/action_mailbox/mailgun/inbound_emails/mime(.:format)
  // function(options)
  rails_mailgun_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"mailgun",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"mime",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_mandrill_inbound_emails => /rails/action_mailbox/mandrill/inbound_emails(.:format)
  // function(options)
  rails_mandrill_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"mandrill",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_mandrill_inbound_health_check => /rails/action_mailbox/mandrill/inbound_emails(.:format)
  // function(options)
  rails_mandrill_inbound_health_check_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"mandrill",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_postmark_inbound_emails => /rails/action_mailbox/postmark/inbound_emails(.:format)
  // function(options)
  rails_postmark_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"postmark",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_relay_inbound_emails => /rails/action_mailbox/relay/inbound_emails(.:format)
  // function(options)
  rails_relay_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"relay",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_sendgrid_inbound_emails => /rails/action_mailbox/sendgrid/inbound_emails(.:format)
  // function(options)
  rails_sendgrid_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"sendgrid",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_service_blob => /rails/active_storage/blobs/redirect/:signed_id/*filename(.:format)
  // function(signed_id, filename, options)
  rails_service_blob_path: Utils.route([["signed_id",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"blobs",false],[2,[7,"/",false],[2,[6,"redirect",false],[2,[7,"/",false],[2,[3,"signed_id",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// rails_service_blob_proxy => /rails/active_storage/blobs/proxy/:signed_id/*filename(.:format)
  // function(signed_id, filename, options)
  rails_service_blob_proxy_path: Utils.route([["signed_id",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"blobs",false],[2,[7,"/",false],[2,[6,"proxy",false],[2,[7,"/",false],[2,[3,"signed_id",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// report => /reports/:id(.:format)
  // function(id, options)
  report_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// reports => /reports(.:format)
  // function(options)
  reports_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// reset_admin_organisation => /admin/organisations/:id/reset(.:format)
  // function(id, options)
  reset_admin_organisation_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"organisations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"reset",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// revaluate_admin_managed_asset => /admin/managed_assets/:id/revaluate(.:format)
  // function(id, options)
  revaluate_admin_managed_asset_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"managed_assets",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"revaluate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// revaluate_update_admin_managed_asset => /admin/managed_assets/:id/revaluate_update(.:format)
  // function(id, options)
  revaluate_update_admin_managed_asset_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"managed_assets",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"revaluate_update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// root => /
  // function(options)
  root_path: Utils.route([], {}, [7,"/",false]),
// show_files_admin_managed_asset => /admin/managed_assets/:id/show_files(.:format)
  // function(id, options)
  show_files_admin_managed_asset_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"managed_assets",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"show_files",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// show_overview_admin_xero_logs => /admin/xero_logs/show_overview(.:format)
  // function(options)
  show_overview_admin_xero_logs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"xero_logs",false],[2,[7,"/",false],[2,[6,"show_overview",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// show_files_admin_minor_item => /admin/minor_items/:id/show_files(.:format)
  // function(id, options)
  show_files_admin_minor_item_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"minor_items",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"show_files",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// sync => /syncs/:id(.:format)
  // function(id, options)
  sync_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"syncs",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// sync_accounts_admin_categories => /admin/categories/sync_accounts(.:format)
  // function(options)
  sync_accounts_admin_categories_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"categories",false],[2,[7,"/",false],[2,[6,"sync_accounts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// update_codes_admin_draft_assets => /admin/draft_assets/update_codes(.:format)
  // function(options)
  update_codes_admin_draft_assets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"draft_assets",false],[2,[7,"/",false],[2,[6,"update_codes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// update_disposal_code_admin_categories => /admin/categories/update_disposal_code(.:format)
  // function(options)
  update_disposal_code_admin_categories_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"categories",false],[2,[7,"/",false],[2,[6,"update_disposal_code",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// update_order_admin_categories => /admin/categories/update_order(.:format)
  // function(options)
  update_order_admin_categories_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"categories",false],[2,[7,"/",false],[2,[6,"update_order",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// update_rails_disk_service => /rails/active_storage/disk/:encoded_token(.:format)
  // function(encoded_token, options)
  update_rails_disk_service_path: Utils.route([["encoded_token",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"disk",false],[2,[7,"/",false],[2,[3,"encoded_token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// upload_file_admin_managed_asset => /admin/managed_assets/:id/upload_file(.:format)
  // function(id, options)
  upload_file_admin_managed_asset_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"managed_assets",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"upload_file",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// upload_file_admin_minor_item => /admin/minor_items/:id/upload_file(.:format)
  // function(id, options)
  upload_file_admin_minor_item_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"minor_items",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"upload_file",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user_confirmation => /users/confirmation(.:format)
  // function(options)
  user_confirmation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"confirmation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_password => /users/password(.:format)
  // function(options)
  user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_registration => /users(.:format)
  // function(options)
  user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// user_session => /users/sign_in(.:format)
  // function(options)
  user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_unlock => /users/unlock(.:format)
  // function(options)
  user_unlock_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"unlock",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_xero_oauth2_omniauth_authorize => /users/auth/xero_oauth2(.:format)
  // function(options)
  user_xero_oauth2_omniauth_authorize_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"xero_oauth2",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_xero_oauth2_omniauth_callback => /users/auth/xero_oauth2/callback(.:format)
  // function(options)
  user_xero_oauth2_omniauth_callback_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"xero_oauth2",false],[2,[7,"/",false],[2,[6,"callback",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// value_override_admin_managed_asset => /admin/managed_assets/:id/value_override(.:format)
  // function(id, options)
  value_override_admin_managed_asset_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"managed_assets",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"value_override",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// value_override_update_admin_managed_asset => /admin/managed_assets/:id/value_override_update(.:format)
  // function(id, options)
  value_override_update_admin_managed_asset_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"managed_assets",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"value_override_update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// xero_oauth2_session_create => /xero_oauth2_session/create(.:format)
  // function(options)
  xero_oauth2_session_create_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"xero_oauth2_session",false],[2,[7,"/",false],[2,[6,"create",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// xero_oauth2_session_disconnect => /xero_oauth2_session/disconnect(.:format)
  // function(options)
  xero_oauth2_session_disconnect_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"xero_oauth2_session",false],[2,[7,"/",false],[2,[6,"disconnect",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// xero_oauth2_session_show => /xero_oauth2_session/show(.:format)
  // function(options)
  xero_oauth2_session_show_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"xero_oauth2_session",false],[2,[7,"/",false],[2,[6,"show",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// xero_oauth2_session_test => /xero_oauth2_session/test(.:format)
  // function(options)
  xero_oauth2_session_test_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"xero_oauth2_session",false],[2,[7,"/",false],[2,[6,"test",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// xero_oauth2_sso_appstore_sign_up => /xero_oauth2_sso/appstore_sign_up(.:format)
  // function(options)
  xero_oauth2_sso_appstore_sign_up_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"xero_oauth2_sso",false],[2,[7,"/",false],[2,[6,"appstore_sign_up",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// xero_oauth2_sso_sign_up => /xero_oauth2_sso/sign_up(.:format)
  // function(options)
  xero_oauth2_sso_sign_up_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"xero_oauth2_sso",false],[2,[7,"/",false],[2,[6,"sign_up",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// xero_oauth2_sso_sign_up_create => /xero_oauth2_sso/sign_up_create(.:format)
  // function(options)
  xero_oauth2_sso_sign_up_create_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"xero_oauth2_sso",false],[2,[7,"/",false],[2,[6,"sign_up_create",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]])}
;
      routes.configure = function(config) {
        return Utils.configure(config);
      };
      routes.config = function() {
        return Utils.config();
      };
      routes.default_serializer = function(object, prefix) {
        return Utils.default_serializer(object, prefix);
      };
      return Object.assign({
        "default": routes
      }, routes);
    }
  };

  result = Utils.make();

  if (typeof define === "function" && define.amd) {
    define([], function() {
      return result;
    });
  } else if (typeof module !== "undefined" && module !== null) {
    try {
      module.exports = result;
    } catch (error1) {
      error = error1;
      if (error.name !== 'TypeError') {
        throw error;
      }
    }
  } else {
    Utils.namespace(this, null, result);
  }

  return result;

}).call(this);
