window.Dropzone.options.assetAttachmentDropzone = {
  success: function(file, response) {
    if (response.success) {
      document.querySelector('[data-attachments]').innerHTML = response.html;
    }
  },
  error: function(file, response) {
    console.error('File upload failed:', response);
  }
};

window.Dropzone.options.itemAttachmentDropzone = {
  success: function(file, response) {
    if (response.success) {
      document.querySelector('[data-attachments]').innerHTML = response.html;
    }
  },
  error: function(file, response) {
    console.error('File upload failed:', response);
  }
};

